import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import axios from "axios";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css'; // Import CSS
import '../MachineLearning/style.css'
import logo from '../../assets/IIIT Bangalore.png'
import LEARNING from '../../assets/learning.webp'
import tools from '../../assets/tools.webp';
import Pro1 from '../../assets/pro1.webp'
import Pro2 from '../../assets/pro2.webp'
import { Helmet } from "react-helmet-async";

const FullStackDevelopment = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [error, setError] = useState({
        name: "",
        email: "",
        contact: "",
        selectedOption: ""
    })


    const handlePhoneNumberChange = (isValid, value, countryData, number, id) => {
        setContact(value);
    };




    async function checkForDuplicateLead(email, phone) {
        const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/27mcmp94vg9akfjx/crm.lead.list.json';
        // Prepare query parameters
        const queryParams = new URLSearchParams();
        if (email) {
            queryParams.append('filter[EMAIL]', email);
        }
        if (phone) {
            queryParams.append('filter[PHONE]', phone);
        }
        queryParams.append('select[]', 'ID'); // Add ID to the select array
        const response = await fetch(`${webhookUrl}?${queryParams.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.result.length > 0 ? result.result[0].ID : null;
    }
    async function addLeadToBitrix24(name, email, phone, course, soursevalue) {
        const existingLeadId = await checkForDuplicateLead(email, phone);
        console.log('ooooo', existingLeadId);
        if (existingLeadId) {
            alert('Your email or phone number is already registered in our system. Our team will be in touch with you shortly..');
            return;
        } else {
            const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/27mcmp94vg9akfjx/crm.lead.add.json';
            const data = {
                fields: {
                    TITLE: 'https://iop.liba.edu/',
                    NAME: name,
                    EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                    PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
                    SOURCE_ID: soursevalue,
                    UF_CRM_1637687988896: course // Adding source as source description
                }
            };
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                if (result.error) {
                    alert('Error: ' + result.error_description);
                } else {

                }
            } catch (error) {
                console.error('Fetch error:', error);
                alert('Error: ' + error);
            }
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset error state
        let errors = {
            name: "",
            email: "",
            contact: "",
            selectedOption: ""
        };

        // Basic validation
        let isValid = true;
        if (!name) {
            errors.name = "Name is required";
            isValid = false;
        }

        if (!contact) {
            errors.contact = "Contact is required";
            isValid = false;
        } else {
            const contactRegex = /^\d{10}$/;
            if (!contactRegex.test(contact)) {
                errors.contact = "Invalid contact number";
                isValid = false;
            }
        }

        if (!email) {
            errors.email = "Email is required";
            isValid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                errors.email = "Invalid email format";
                isValid = false;
            }
        }

        if (!selectedOption) {
            errors.selectedOption = "Option is required";
            isValid = false;
        }

        setError(errors);
        if (!isValid) {
            return;
        }

        let data = JSON.stringify({
            contact: contact,
            name: name,
            email: email,
            cou: selectedOption ? selectedOption.value : ''
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.123admissions.com/api/v1/maipalform',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));

                addLeadToBitrix24(name, email, contact, '2301', 'WEB')
                setSelectedOption(null)
                setName('')
                setContact('')
                setEmail('')

            })
            .catch((error) => {
                // console.log(error);
            });
    };
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            window.intlTelInput(inputRef.current, {
                initialCountry: 'in',
                separateDialCode: false, // Set to false to hide the flag
            });
        }
    }, []);
    const options = [
        { value: '1', label: 'Executive PG Programme in Machine Learning & Artificial Intelligence' },
        { value: '2', label: 'Post Graduate Certification in Executive ai-powered Full Stack Development' },
        { value: '3', label: 'Executive Post-Graduate Certificate In Cloud Computing And Devops' },

    ];
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Option selected:`, selectedOption);
    };
    
    return (<>
        <Helmet>
            <title>Post Graduate Certification in Executive AI-powered Full Stack Development - IIIT Bangalore</title>
            <meta
                name="description"
                content="Enroll in the Post Graduate Certification in Executive AI-powered Full Stack Development at IIIT Bangalore. Master skills in AI, front-end and back-end development, and build intelligent applications."
            />
            <meta
                name="keywords"
                content="IIIT Bangalore, Full Stack Development, AI-powered Full Stack, Post Graduate Certification, AI, front-end development, back-end development"
            />
            <meta property="og:title" content="Post Graduate Certification in Executive AI-powered Full Stack Development - IIIT Bangalore" />
            <meta
                property="og:description"
                content="Advance your career with the Post Graduate Certification in Executive AI-powered Full Stack Development from IIIT Bangalore. Learn to build AI-powered applications with expert guidance."
            />
            <meta property="og:url" content="https://yourwebsite.com/iiit-bangalore/fullstack-ai" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://yourwebsite.com/images/fullstack-ai-banner.jpg" />
            <meta property="og:site_name" content="IIIT Bangalore Online Programmes" />
        </Helmet>
        <div className="landingpage">
            <div className="header">
                <div className="logo">
                    <img src={logo}></img>
                </div>

                <div className="row">
                    <div className="col-lg-12"></div>
                </div>
            </div>
            <div className="applynow">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text">
                            Get ready! The next batch kicks off in 15 days. Reserve your place today!
                            <b style={{ color: "red", marginLeft: "10px" }}>APPLY NOW</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contents container">

                <div className="row">
                    <div className="col-lg-12">  <h1 style={{ marginTop: "5%" }}>Post Graduate Certification in Executive ai-powered Full Stack Development</h1></div>
                    <div className="col-lg-6 mt-3 order2">
                        <h4 className="h2tag" style={{ textAlign: "start", margin: "0px", padding: "0px" }}>About the Programme</h4>
                        <p>The Post Graduate Certification in Executive AI-powered Full Stack Development is a specialized program designed to equip professionals with cutting-edge skills in both front-end and back-end web development, leveraging Artificial Intelligence (AI) technologies to enhance application performance, user experience, and automation capabilities.</p>
                        <div className="row">
                            <div className="col-lg-12">
                                <label className="mb-3">Act fast! 691 people have already applied this month – limited seats available!</label>
                            </div>
                          
                            <div className="col-lg-4 col-6 mt-3">
                                <label className="h2tag mb-2" >Start Date:</label><br></br>
                                <label>30 september</label>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <label className="h2tag mb-2" >Duration</label> <br></br>
                                <label>9 Months</label>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <div className="form">
                            <div className="row">
                                <div className="col-12">
                                    Enter your details and our experts will get in touch with you shortly!
                                </div>
                                <div className="col-12 mt-2" style={{ textAlign: "start" }}>
                                    <input
                                        className="form-control"
                                        placeholder="Enter your name*"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.name}</small>
                                </div>
                                <div className="col-12 mt-2" style={{ textAlign: "start" }}>
                                    <input
                                        className="form-control"
                                        placeholder="yourname@email.com*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.email}</small>
                                </div>
                                <div className="col-12 mt-2" style={{ textAlign: "start", color: "black" }}>
                                    <IntlTelInput
                                        inputProps={{ id: '91', name: 'india', ref: inputRef }}
                                        onPhoneNumberChange={handlePhoneNumberChange}
                                        defaultCountry="in"
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.contact}</small>
                                </div>
                                <div className="col-12 mt-2" style={{ color: "black", textAlign: "start" }}>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleChange}
                                        options={options}
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.selectedOption}</small>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ textAlign: "start", fontSize: "10px" }}>
                                        <input style={{ marginRight: "10px" }} type="checkbox" required />
                                        I authorize International Institute of Information Technology Bangalore and its associates to contact me with updates & notifications via email, SMS, WhatsApp, and voice call. This consent will override any registration for DNC / NDNC.
                                    </div>
                                </div>
                                <div className="col-12 mt-5">
                                    <button className="submit btn btn-primary w-100" type="submit" onClick={handleSubmit}>APPLY NOW</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr></hr>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="program-overview">
                            <h4 className="h2tag">Programming Languages & Tools</h4>
                            <div className="row mt-3 mb-5">
                                <div className="col-lg-6">
                                    <p>Learn programming languages and tools like:</p>
                                    <ul>
                                        <li>
                                            Java, DSA, Git, OOPS, JUnit, Python, SQL, Apache Hadoop, Spark, Kafka, Sqoop, Hive, Airflow, Amazon Redshift, Amazon Glue, ElasticSearch, Kibana, Tableau & more
                                        </li>
                                    </ul>


                                    <h5>100+ DSA Questions to Practice</h5>
                                    <p>Solve 100+ DSA questions throughout the program to master this interview skill.</p>

                                    <h5>Blended Learning</h5>
                                    <p>Learn with the ease and flexibility of recorded sessions as well as live sessions, designed to ensure a wholesome learning experience.</p>

                                </div>
                                <div className="col-lg-6">
                                    <h5>Personalised Mentorship</h5>
                                    <p>Get unparalleled personalised mentorship and doubt resolution from Industry experts.</p>

                                    <h5>Hands-on Learning</h5>
                                    <p>Experiential Learning for Software Development Life Cycle:</p>
                                    <ul>
                                        <li>Git and Version control concepts</li>
                                        <li>DSA Bootcamp for transition learners</li>
                                        <li>Design, Develop & Deploy - Data Pipelines & Dashboards for Pandemic Tracking and Online Gaming User Activities.</li>
                                        <li>Work on 4 industry projects to apply your learnings.</li>
                                        <li>5+ hands-on practice projects & various Big Data coding questions to keep your hands dirty.</li>
                                    </ul>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div className="col-lg-12 mb-5">
                        <div class="menternship">

                            <h4 className="h2tag">Mentorship + Internship</h4>
                            <p >
                                A menternship is where students & young professionals can work on real-time situations.
                                These Menternships are procured in collaboration with organizations from various industrial & functional backgrounds.
                                Menternship will include 1:1 sessions and a chat feature to discuss doubts with your mentor.
                                The maximum duration for completing this Menternship project is 60 days.
                                However, the total learner time required will be around 20 hours.
                                A simple user interface coupled with game concepts facilitates an engaging experience.
                                These well-curated projects will help the learners build real skills in the careers of their choice.
                            </p>
                        </div>

                    </div>
                    <div className="col-lg-4 mb-5">
                        <div className="card" style={{ height: "550px", overflowY: "hidden" }}>
                            <div class="profile" style={{ textAlign: "center" }}>

                                <img src={Pro2} style={{ borderRadius: "50%", width: "40%", marginTop: "10px" }}></img>

                                <h5 style={{ color: "white" }}>Chandrashekar Ramanathan</h5>
                                <p class="profile-title" style={{ color: "white" }}>Professor & Dean (Academics)</p>
                                <p style={{ color: "white", padding: "20px" }}>Dr. Chandrashekar Ramanathan, a faculty at IIIT-B since 2007, has spent 25+ years in computing, and he specializes in data science, software engineering, and large-scale app development. As head of MINRO and CTRI-DG he focuses on Data Science and Tech for Education. He has authored research papers, supervised PhD students, and served on government advisory panels. Passionate about tech-enhanced education, he was a BITES board member (2013-2023).</p>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-4">
                        <div className="card" style={{ height: "550px", overflowY: "hidden" }}>
                            <div class="profile" style={{ textAlign: "center" }}>
                                <img src={Pro1} style={{ borderRadius: "50%", width: "40%", marginTop: "10px" }}></img>
                                <h5 style={{ color: "white" }}>Meenakshi D'souza</h5>
                                <p class="profile-title" style={{ color: "white" }}>Professor & M.S. Ph.D. Programme Coordinator</p>
                                <p style={{ color: "white", padding: "20px" }}>Prof. Meenakshi is a distinguished faculty at IIIT Bangalore, specializing in Formal Methods, Software Testing, and Model-Based Development for Embedded Software. Committed to gender diversity and IT accessibility, she teaches impactful courses and her NPTEL Swayam Software Testing course has attracted 57,000+ learners since 2017. With a Theoretical Computer Science background, she has made significant contributions during her tenure at Honeywell Technology Solutions.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content">
                <div className='row'>
                    <div className='col-lg-6 order2' >
                        <img src={logo} style={{ background: "white" }}></img>
                        <a style={{ marginLeft: "40px" }} href="/PrivacyPolicy" className="footer-link">Privacy Policy</a>
                    </div>
                    <div className='col-lg-6'>
                        <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Machine Learning & Artificial Intelligence</a><br></br>
                        <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Ai-powered Full Stack Development</a><br></br>
                        <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Cloud Computing And Devops</a><br></br>
                    </div>
                </div>


            </div>
        </div>
    </>)
}
export default FullStackDevelopment;